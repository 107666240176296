import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";

@Component({
	selector: "app-notification",
	templateUrl: "./notification.component.html",
	styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent {
	@Input() public type: "error" | "warning" | "success" = "success";
	@Input() public message?: string;
	public close$$ = new Subject<void>();
}
