import {
	Component, ChangeDetectionStrategy, ViewChild, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef, Type,
	SimpleChange, SimpleChanges, OnChanges, ComponentRef, HostBinding, OnDestroy
} from "@angular/core";
import { NotificationComponent } from "@shared/notification/notification.component";
import { take, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-overlay",
	styleUrls: ["./overlay.component.scss"],
	template: "<ng-template #anchor></ng-template>"
})
export class OverlayComponent implements OnDestroy {
	@ViewChild("anchor", { read: ViewContainerRef, static: true }) private viewContainerRef?: ViewContainerRef;
	@HostBinding("class.center") public isCenter = false;
	private unsubscribe$$ = new Subject<void>();

	constructor(
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		public readonly changeDetectorRef: ChangeDetectorRef
	) { }

	public showNotification(type: "error" | "warning" | "success", message: string, opts: { delay?: number, withoutDelay?: boolean } = { delay: 5000, withoutDelay: false }): NotificationComponent {
		if (!this.viewContainerRef) {
			throw Error("No viewContainerRef");
		}
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationComponent);
		const index = this.viewContainerRef.length;
		const componentRef = this.viewContainerRef.createComponent(componentFactory);
		const subscription = componentRef.instance.close$$.pipe(
			take(1)
		).subscribe(() => this.viewContainerRef?.remove(index));
		componentRef.onDestroy(() => subscription.unsubscribe());
		this.setInputs(componentRef, { type, message });
		this.changeDetectorRef.detectChanges();
		if (opts && !opts.withoutDelay) {
			setTimeout(() => componentRef.destroy(), opts.delay);
		}
		return componentRef.instance;
	}

	public showComponent<T extends { close$?: Observable<void> }>(component: Type<T>, options?: {
		inputs: { [key: string]: any },
		centerPosition?: boolean
	}): ComponentRef<T> {
		this.isCenter = options ? !!options.centerPosition : false;
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
		const index = this.viewContainerRef.length;
		const componentRef = this.viewContainerRef.createComponent(componentFactory);
		const subscription = componentRef.instance?.close$.pipe(
			take(1)
		).subscribe(() => {
			this.viewContainerRef?.remove(index);
			this.isCenter = false;
		});
		componentRef.onDestroy(() => subscription.unsubscribe());

		if (options) {
			this.setInputs(componentRef, options.inputs);
		}
		this.changeDetectorRef.markForCheck();
		return componentRef;
	}

	public showConfirmation<T extends { appConfirm$?: Observable<boolean> }>(component: Type<T>, options?: {
		inputs: { [key: string]: any }
	}): ComponentRef<T> {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
		const componentRef = this.viewContainerRef.createComponent(componentFactory);
		const subscription = componentRef.instance?.appConfirm$.pipe(
			takeUntil(this.unsubscribe$$),
		).subscribe(() => this.viewContainerRef.clear());
		componentRef.onDestroy(() => subscription.unsubscribe());

		if (options) {
			this.setInputs(componentRef, options.inputs);
		}
		this.changeDetectorRef.markForCheck();
		return componentRef;
	}

	public destroy(): void {
		if (!this.viewContainerRef) {
			throw Error("No viewContainerRef");
		}
		this.isCenter = false;
		this.viewContainerRef.clear();
		this.changeDetectorRef.markForCheck();
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}


	private setInputs<T>(component: ComponentRef<T>, inputs: { [key: string]: any }): void {
		if (inputs) {
			for (const key of Object.keys(inputs)) {
				(component.instance as any)[key] = inputs[key];
			}
			const onChanges = component.instance as any as OnChanges;
			if (onChanges.ngOnChanges) {
				const changes: SimpleChanges = {};
				for (const key of Object.keys(inputs)) {
					(onChanges as any)[key] = inputs[key];
					changes[key] = new SimpleChange(undefined, inputs[key], false);
				}
				onChanges.ngOnChanges(changes);
			}
		}
	}
}
